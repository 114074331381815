button,
.btn {
  border-radius: 0;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: bold;
  outline: none;
  border: 2px solid transparent;
  background-color: transparent;

  &:focus {
    outline: -webkit-focus-ring-color auto 1px;
    box-shadow: none;
  }
  @-moz-document url-prefix() {
    &:focus-visible {
      outline: 2px solid #007bff;
    }
  }
  &:focus:not(:focus-visible) {
    outline: none;
  }

  @include media-breakpoint-up(sm) {
    font-size: 1.4rem;
  }
  &:active {
    transform: scale(0.98);
    outline: none;
  }

  &:disabled {
    cursor: default;
    transform: scale(1);
    opacity: 1;
    color: $white;
    border: 2px solid transparent;
  }

  &.btn-primary {
    background-color: $orange;
    color: $l-black;
    border: 2px solid transparent;
    @include btn-mixin(3.4rem, big);

    span {
      @include icon-mixin(after, "\f054", 1rem, 0.8rem);
    }
    &:disabled {
      background-color: $gray;
      color: $white;
      opacity: 1;
    }
    @-moz-document url-prefix() {
      &:focus-visible {
        outline: 2px solid #007bff;
      }
    }
    &:focus {
      outline: -webkit-focus-ring-color auto 1px;
      box-shadow: none;
    }
    &:focus:not(:focus-visible) {
      outline: none;
    }

    &:enabled {
      &:hover {
        background-color: $red-orange;
      }
      &:active {
        background-color: $dark-orange !important;
        color: $white;
        border-color: $orange !important;
        border: 2px solid;
        box-shadow: none;
        outline: none;
      }
    }
  }

  &.btn-secondary {
    @include btn-mixin(3.6rem, small);
    @include media-breakpoint-up(sm) {
      @include btn-mixin(3.4rem, big);
    }
    span {
      @include icon-mixin(before, "\f053", 1rem, 0.8rem);
    }
  }

  &.btn-start-recording {
    background-color: $orange;
    color: $l-black;
    @include btn-mixin(4.2rem, small);

    span {
      @include icon-mixin(before, "\f130", 1.7rem, 0.6rem);
    }
    &:disabled {
      background-color: $gray;
      color: $white;
    }
    &:enabled {
      @-moz-document url-prefix() {
        &:focus-visible {
          outline: 2px solid #007bff;
        }
      }
      &:focus {
        outline: -webkit-focus-ring-color auto 1px;
        box-shadow: none;
      }
      &:focus:not(:focus-visible) {
        outline: none;
      }
      &:hover {
        background-color: $red-orange;
      }
      &:active {
        background-color: $dark-orange !important;
        color: $white;
        border-color: $orange !important;
        border: 2px solid;
        box-shadow: none;
        outline: none;
      }
    }
  }

  &.btn-stop-recording {
    @include btn-mixin(4.2rem, small);
    background-color: $orange !important;
    color: $l-black !important;
    border: 2px solid transparent;

    span {
      @include icon-mixin(before, "\f04d", 1.7rem, 0.6rem);
    }
    &:enabled {
      @-moz-document url-prefix() {
        &:focus-visible {
          outline: 2px solid #007bff;
        }
      }
      &:focus {
        outline: -webkit-focus-ring-color auto 1px !important;
        box-shadow: none;
      }
      &:focus:not(:focus-visible) {
        outline: none;
      }
      &:hover {
        background-color: $red-orange;
      }
      &:active {
        background-color: $dark-orange !important;
        color: $white !important;
        border-color: $orange !important;
        border: 2px solid;
        box-shadow: none;
        outline: none;
      }
    }
  }

  &.btn-play-recording {
    @include btn-mixin(4.2rem, small);
    background-color: $orange;
    border: 2px solid transparent;
    color: $l-black;

    &:disabled {
      background-color: $gray;
      color: $white;
      opacity: 1;
    }
    span {
      @include icon-mixin(before, "\f04b", 1.7rem, 0.6rem);
    }
    &:enabled {
      @-moz-document url-prefix() {
        &:focus-visible {
          outline: 2px solid #007bff;
        }
      }
      &:focus {
        outline: -webkit-focus-ring-color auto 1px;
        box-shadow: none;
      }
      &:focus:not(:focus-visible) {
        outline: none;
      }
      &:hover {
        background-color: $red-orange;
      }
      &:active {
        background-color: $dark-orange !important;
        color: $white;
        border-color: $orange !important;
        border: 2px solid;
        box-shadow: none;
        outline: none;
      }
    }
  }

  &.btn-toggle {
    background-color: $orange;
    color: $white;
  }

  &.btn-up {
    @include icon-mixin(after, "\f139", 1.4rem, 0.8rem);
  }

  &.btn-down {
    @include icon-mixin(after, "\f13a", 1.4rem, 0.8rem);
  }

  &.btn-video-play {
    background-color: $orange;
    box-shadow: none;
    height: 3.6rem;
    width: 3.6rem;

    &::before {
      color: $l-black;
      content: "\f04b";
      font-family: FontAwesome;
    }
    &:enabled {      
      @-moz-document url-prefix() {
        &:focus-visible {
          outline: 2px solid #007bff;
        }
      }
      &:focus {
        outline: -webkit-focus-ring-color auto 1px;
        box-shadow: none;
      }
      &:focus:not(:focus-visible) {
        outline: none;
      }
      &:hover {
        background-color: $red-orange;
      }
      &:active {
        background-color: $dark-orange !important;
        color: $white;
        border-color: $orange !important;
        border: 2px solid;
        box-shadow: none;
        outline: none;
      }
    }
  }

  &.btn-video-replay {
    background-color: $orange;
    box-shadow: none;
    height: 3.6rem;
    width: 3.6rem;

    &::before {
      color: $l-black;
      content: "\f0e2";
      font-family: FontAwesome;
    }
    &:enabled {
      @-moz-document url-prefix() {
        &:focus-visible {
          outline: 2px solid #007bff;
        }
      }
      &:focus {
        outline: -webkit-focus-ring-color auto 1px;
        box-shadow: none;
      }
      &:focus:not(:focus-visible) {
        outline: none;
      }
      &:hover {
        background-color: $red-orange;
      }
      &:active {
        background-color: $dark-orange !important;
        color: $white;
        border-color: $orange;
        box-shadow: none;
        outline: none;
      }
    }
  }

  &.btn-disabled {
    background-color: $gray;
  }
}

.btn-pin-bottom {
  @include media-breakpoint-up(lg) {
    margin-top: 4.2rem;

    .col {
      bottom: 2rem;
      position: absolute;
      right: 0;
    }

    .form-group {
      margin-bottom: 0;
    }
  }

  .form-group {
    display: inline-block;
    margin-top: 1.4rem;
    text-align: right;
    width: 100%;

    &.group-nav-buttons {
      .btn-secondary {
        @include media-breakpoint-up(md) {
          margin-right: 0.8rem;
          margin-top: 0;
        }

        margin-top: 0.8rem;
      }
    }
  }
}

.sign-out {
  color: $gray;
  display: inline-block;
  text-decoration: none;

  &:hover {
    color: $l-black;
  }

  &:active {
    transform: scale(0.98);
    color: $l-black;
  }

  span {
    font-weight: 700;
    font-size: 1.2rem;
    @include media-breakpoint-up(md) {
      font-size: 1.4rem;
    }

    &::before {
      background: url("#{$assetpath}/images/icon-sign-out.png") no-repeat;
      content: "";
      display: inline-block;
      height: 2.1rem;
      margin-right: 0.5rem;
      vertical-align: -0.5rem;
      width: 1.8rem;
    }
    &:hover {
      &::before {
        background: url("#{$assetpath}/images/icon-sign-out-dark.png") no-repeat;
      }
    }
  }
}
