.block-system-check {
  button,
  .btn {
    box-shadow: none;
  }

  a {
    font-weight: bold;

    &.btn-goto-test {
      color: $l-black;
      line-height: 3.4rem;
      text-decoration: none;
    }
  }
}

.system-check-table {
  border-collapse: separate;
  border-spacing: 0 6px;

  p {
    margin-bottom: 0;
  }

  th {
    background-color: $blue;
    color: $white;
    font-size: 1.2rem;
    font-weight: 700;
    height: 3.8rem;
    text-transform: capitalize;
    @include media-breakpoint-up(sm) {
      font-size: 1.4rem;
    }
  }

  tr {
    td {
      @include media-breakpoint-up(sm) {
        font-size: 1.4rem;
        height: 96px;
      }
      font-size: 1.2rem;
      line-height: 16px;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        border: 1px solid $gray;
        border-right: 0;
      }

      &:nth-child(2),
      &:nth-child(3) {
        padding: 0.8rem 0.35rem;

        @include media-breakpoint-up(sm) {
          padding: 1.1rem 1.5rem;
        }
      }

      &:nth-child(1) {
        @include media-breakpoint-up(sm) {
          font-size: 1.8rem;
          min-width: 18rem;
          text-align: center;
        }
        font-size: 1.2rem;
        text-align: center;
      }

      &:nth-child(3) {
        @include media-breakpoint-up(sm) {
          width: 34rem;
        }

        h3 {
          font-size: 1.75rem;
        }
      }

      &.status {
        min-width: 0;
        padding: 0 0.8rem;
        @include media-breakpoint-up(sm) {
          width: 7rem;
        }
      }

      .alert {
        margin-bottom: 0;
        max-width: 30rem;
      }

      .supported-browsers {
        h3 {
          font-size: 1.4rem;
          margin-bottom: 0;
        }

        p {
          font-size: 1.2rem;
          margin-bottom: 0.8rem;
        }
      }
    }

    &.error-msg {
      td {
        background: url('#{$assetpath}/images/icon-alert-blue.png') 3px 9px no-repeat;
        border: 0;
        font-size: 1.4rem;
        height: 4.6rem;
        padding-left: 43px;
        text-align: left;
        @include media-breakpoint-down(sm) {
          font-size: 1.2rem;
          background-size: 20px;
          padding-left: 30px;
        }
      }
    }
  }

  .confirmation {
    .passCheck {
      color: $green;

      &:hover {
        color: $green;
      }
    }

    .failCheck {
      color: $red;

      &:hover {
        color: $red;
      }
    }

    .passCheck,
    .failCheck {
      display: inline-block;
      text-decoration: underline;

      &.disabled {
        color: $gray;
        cursor: default;
      }

      &:hover {
        cursor: pointer;
        text-decoration: none;
      }
      @-moz-document url-prefix() {
        &:focus-visible {
          outline: 2px solid #007bff;
        }
      }
      &:active {
        outline: none;
      }
    }
  }

  //*~*~*~*~*~*~*~* Pass/Fail Icons *~*~*~*~*~*~*~*//
  .waiting {
    @include system-check-status('#{$assetpath}/images/icon-waiting.gif');
  }

  .success {
    @include system-check-status('#{$assetpath}/images/icon-success.png');
  }

  .fail {
    @include system-check-status('#{$assetpath}/images/icon-fail.png');
  }
}

.system-check-result {
  background-color: $l-gray;
  line-height: 40px;
  padding-left: 40px;

  &.success {
    background: $l-gray url('#{$assetpath}/images/icon-system-success.png') no-repeat;
    border-left: 4px solid $green;

    strong {
      color: $green;
    }
  }

  &.fail {
    background: $l-gray url('#{$assetpath}/images/icon-system-fail.png') no-repeat;
    border-left: 4px solid $red;

    strong {
      color: $red;
    }
  }
}

.system-check-footer {
  color: $gray;
  font-size: 1.2rem;
}
