.block-welcome {
  h2 {
    &.opic-header {
      @include title-mixin($brand-opic-blue, true, '#{$assetpath}/images/icon-modality-speaking.png');
    }

    &.wpt-header {
      @include title-mixin($brand-wpt-green, true, '#{$assetpath}/images/icon-modality-writing.png');
    }

    &.lpt-header {
      @include title-mixin($brand-lpt-purple, true, '#{$assetpath}/images/icon-modality-listening.png');
    }

    &.rpt-header {
      @include title-mixin($brand-rpt-orange, true, '#{$assetpath}/images/icon-modality-reading.png');
    }
  }

  p {
    @include media-breakpoint-up(sm) {
      font-size: 1.8rem;
    }

    font-size: 1.3em;
    margin: 0 24px 16px;

    &.pi-alert {
      @include media-breakpoint-up(sm) {
        font-size: 1.4rem;
        margin: 0; 
      }
      font-size: 1.2rem;
      margin: 3rem 24px 0;
    }
  }
}
