.agree-area {
    .tit {
        border-bottom: 1px solid #eee;
        padding: 20px 24px 16px;
        font-weight: bold;
        font-size: 26px;
        color: #111;
    }
    .sub-tit {
        border-bottom: 1px solid eee;
        font-size: 1.20em;
        font-weight: bold;
        margin-bottom: 0;
        padding: 6px 0;
    }


    .agree {
        padding: 21px 24px 30px;
        font-size: 15.5px;
        line-height: 22px;

        * {
            letter-spacing: 0;
        }

        .copy {

        }

        .ac {
            list-style: initial;

            li {
                position: relative; margin: 16px 0; padding: 0 0 0 14px;

                :after {
                    content:'';
                    position: absolute;
                    top: 9px;
                    left: 0;
                    display: block;
                    width: 2px;
                    height: 2px;
                    background-color: #444;
                    box-shadow: 0 0 1px 1px rgba(0,0,0,1);
                    border-radius: 50px;
                }
            }
        }
    }
    .agreearabic {
        padding: 21px 24px 30px;
        font-size: 20.5px;
        line-height: 22px;

        * {
            letter-spacing: 0;
        }

        .copy {

        }

        .ac {
            list-style: initial;

            li {
                position: relative; margin: 16px 0; padding: 0 0 0 14px;

                :after {
                    content:'';
                    position: absolute;
                    top: 9px;
                    left: 0;
                    display: block;
                    width: 2px;
                    height: 2px;
                    background-color: #444;
                    box-shadow: 0 0 1px 1px rgba(0,0,0,1);
                    border-radius: 50px;
                }
            }
        }
    }
    
    .check-line {
        padding-top: 16px;
        @include media-breakpoint-down(sm) {
          font-size: 1.2rem;
        }

        input[type="checkbox"] {
            float: left;
            margin: 3px 11px 4px 3px;
            &:hover{
                border-color: $medium-blue;
            }
        }
        label {display: block;
            overflow: hidden;
            cursor:pointer;
        }
    }

}

@include media-breakpoint-down(sm) {
    .agree-area {
        .agree {
            padding: 21px 2px 30px;
        }
    }
}    