/// login styles
.form-group label,
select.form-control,
input[type='text'].form-control,
.form-group input[type='password'].form-control {
  font-size: 1.8rem;
  @media (max-width: 768px) {
    font-size: 1.6rem;
  }
}

.form-control:focus{
  border-color: $dark-orange;
}

.block-login {
  .alert{
    border-color: $cherry-red;
  }
  .form-group {
    padding: 0 22.4rem;
  }
}

@font-face {
  font-family: 'SecureFont';
  src: url('#{$assetpath}/fonts/SecureFont.woff') format('woff');
  font-style: normal;
  font-weight: normal;
}

.secure-input {
  font-family: 'SecureFont' !important;
  font-size: 10px !important;
}

.alert-danger {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 960px;
  span {
    background: url('#{$assetpath}/images/icon-alert-danger.png') no-repeat center;
    display: flex;
    margin-right: .5rem;
    width: 21px;
    height: 23px;
    background-size: contain;
  }
  @include media-breakpoint-down(sm) {
    width: 104vw;
    margin-left: -16px;
    font-size: 12px;
    font-weight: 700;
  }
}

@include media-breakpoint-down(sm) {
  .block-login {
    .form-group {
      padding: 0 24px;
    }
    .alert{
      margin-top: -3rem;
    }
  }
}