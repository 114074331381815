// Videojs custom styles
.vjs-opic-skin {
  height: $video-height !important;
  width: $video-width !important;
  @include media-breakpoint-down(sm) {
    height: 18rem !important;
    width: 18rem !important;
  }
  &.vjs-user-active {
    @include media-breakpoint-down(sm) {
      height: 18rem;
      width: 18rem;
    }
  }

  &.vjs-system-check {
    @include media-breakpoint-up(sm) {
      height: 20.2rem !important;
      width: 20rem !important;
    }
    height: 10rem !important;
    width: 10rem !important;
  }

  &:hover {
    .vjs-big-play-button {
      background-color: $orange;
    }
  }

  .vjs-tech {
    pointer-events: none;
  }

  .vjs-poster {
    cursor: auto;
  }

  .vjs-big-play-button {
    /* The font size is what makes the big play button...big.
       All width/height values use ems, which are a multiple of the font size.
       If the .video-js font-size is 10px, then 3em equals 30px.*/

    /* We're using SCSS vars here because the values are used in multiple places.
       Now that font size is set, the following em values will be a multiple of the
       new font size. If the font-size is 3em (30px), then setting any of
       the following values to 3em would equal 30px. 3 * font-size. */
    $big-play-width: 3em;
    /* 1.5em = 45px default */
    $big-play-height: 1.5em;

    left: 50%;
    top: 50%;
    margin-left: -($big-play-width / 2);
    margin-top: -($big-play-height / 2);

    background-color: $orange;
    border: 0;
    border-radius: 0;
    box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
    font-size: 2.5rem;
    font-weight: bold;
    outline: none;

    &:focus-visible {
      outline: -webkit-focus-ring-color auto 1px;
      box-shadow: none;
    }
    @-moz-document url-prefix() {
      &:focus-visible {
        outline: 2px solid #007bff;
      }
    }
    &:hover, &:focus {
      background-color: $red-orange;
    }
    &:active {
      background-color: $dark-orange !important;
      color: $white;
      border-color: $orange !important;
      border: 2px solid;
      box-shadow: none;
      outline: none;
    }

    &.vjs-refresh {
      .vjs-icon-placeholder {
        @include icon-mixin(before, '\f0e2', 2.5rem, 0);
      }
    }

    &.vjs-controls-bottom {
      top: calc(100% + 2.4rem);
    }
  }
}
