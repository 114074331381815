.block-mic-access {
  .small-cards {
    .card {
      min-height: 38rem;
    }
  }

  .big-cards {
    .card {
      min-height: 51rem;
    }
  }
}
