.block-response-timer {
  .response-time-well {
    border: 1px solid $l-black;
    height: 20px;

    .response-time {
      background-color: $blue;
      height: 18px;
      width: 50%;
    }
  }

  .remaining-time {
    margin-top: 6px;
    text-align: right;
  }
}

.block-video-control {
  @include media-breakpoint-up(md) {
    width: 30rem;
  }
  width: 18rem;
}

.block-playback {
  background-color: $l-gray;
  display: inline-block;
  height: 3.6rem;
  padding: 1rem;
  width: 100%;

  .track-well {
    border: 1px solid $gray;
    height: .4rem;
    margin-top: .6rem;

    .track {
      background-color: $blue;
      height: .2rem;
      width: 0%;
    }
  }
}

.alert-recording {
  background-color: $blue;
  box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, .2);
  color: $white;
  height: 4.6rem;
  line-height: 4.6rem;
  text-align: center;
  width: 100%;

  span {
    position: relative;

    &::before {
      animation: stretch .8s linear infinite;
      background-color: $white;
      border-radius: 100%;
      content: '';
      display: inline-block;
      height: 2.5rem;
      margin-right: 1rem;
      vertical-align: -.8rem;
      width: 2.5rem;
    }

    &::after {
      background-color: $white;
      border-radius: 100%;
      content: url('#{$assetpath}/images/icon-recording3.png');
      display: inline-block;
      height: 2.5rem;
      left: 0;
      line-height: 3.2rem;
      margin-right: 1rem;
      position: absolute;
      top: -.4rem;
      width: 2.5rem;
    }
  }
}

.alert-uploading {
  text-align: center;

  span {
    &::before {
      animation: rotate .8s linear infinite;
      content: url('#{$assetpath}/images/icon-waiting.png');
      display: inline-block;
      height: 2rem;
      margin-right: .6rem;
      vertical-align: -.4rem;
      width: 2rem;
    }
  }
}

@keyframes stretch {
  0% {
    opacity: .5;
    transform: scale(1);
  }

  100% {
    opacity: .2;
    transform: scale(1.5);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.alert-blue {
  background-color: $blue;
  color: $white;
  padding: 1rem;
}

.alert-purple {
  background: $purple url('#{$assetpath}/images/icon-alert.png') no-repeat 1rem 1rem;
  color: $white;
  padding: 1.2rem 1rem 1.2rem 3.6rem;
  max-width: 632px;
  text-align: center;
}

.alert-blue,
.alert-purple {
  p {
    margin-bottom: 0;
  }
}

.error-page-header,
.message-begin {
  color: $dark-orange;
  font-size: 2.5rem;
  font-weight: normal;
}

.block-mic-gauge {
  display: flex;
  flex-direction: column;
  width: 1rem;

  &.disable {
    .mic-gauge-well {
      .mic-gauge {
        .mic-gauge-marker {
          background-color: $gray;
        }
      }
    }

    .mic-icon {
      color: $gray;
    }
  }

  .mic-gauge-well {
    border: 1px solid $silver;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 2rem;
    margin-left: .4rem;
    width: .4rem;

    .mic-gauge {
      background-color: $blue;
      height: 0%;
      position: relative;

      .mic-gauge-marker {
        background-color: $l-black;
        border-radius: 100%;
        height: 1rem;
        left: -.4rem;
        position: absolute;
        width: 1rem;
      }
    }
  }

  .mic-icon {
    @include icon-mixin('before', '\f130', 2rem, 0);
    flex-shrink: 1;
  }
}

.set-to-video-height {
  @include media-breakpoint-up(md) {
    height: $video-height;
  }
  height: 180px;
}

.set-to-video-width {
  @include media-breakpoint-up(md) {
    width: $video-width;
  }
  width: 18rem;
}

.lrn_start_recording {
  display: none !important;
}

#sample-question-description,
#sample-question-instructions,
#replay-instructions,
#recording-instructions,
#responseTimer,
#recordingStatus {
  @include media-breakpoint-up(md) {
    font-size: 1.4rem;
  }
  font-size: 1.2rem;
}
