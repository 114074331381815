.block-test-complete {
  h1 {
    b {
      color: $blue !important;
      font-size: 2.2rem;
      @include media-breakpoint-up(md) {
        font-size: 3.6rem;
      }
    }
  }
  h2 {
    font-size: 2rem;
    @include media-breakpoint-up(md) {
      font-size: 2.5rem;
    }
  }
  img {
    width: 60px;
    @include media-breakpoint-up(md) {
      width: auto;
    }
  }
}
