.block-survey {
  h2 {
    @include media-breakpoint-down(sm) {
      font-size: 1.6rem;
    }
    &.header-primary {
      margin-top: 1.4rem;
    }
  }

  p {
    &.instructions-survey {
      margin-top: 1.4rem;
      @include media-breakpoint-down(sm) {
        font-size: 1.2rem;
      }
      .selection-count{
        display: flex;
        align-items: flex-end;
        color: $red;
        span {
          background: url('#{$assetpath}/images/icon-alert-danger-survey.png') no-repeat center;
          display: flex;
          margin-right: .5rem;
          width: 21px;
          height: 23px;
          background-size: contain;
        }
      }
    }
  }

  .background-survey-question {
    font-size: 1.8rem;
    margin: 0 auto 10px;
    padding-top: 6px;
  }

  .form-check {
    margin-bottom: 1rem;
    @include media-breakpoint-down(sm) {
      padding-left: .55rem;
    }
  }

  .form-check-label {
    padding-left: .75rem;
    @include media-breakpoint-down(sm) {
      font-size: 1.2rem;
    }
  }

  .form-check-input {
    margin-left: -1.75rem;
  }

  .question-error-msg {
    color: red;
    display: none;
    font-weight: bold;
    margin-top: -5px;
    padding-bottom: 5px;
    padding-left: 5px;
  }
}
