/// form styles
input {
  &[type='text'],
  &[type='password'] {
    @include form-field-mixin;
  }
}

select {
  &.form-control {
    @include form-field-mixin;
  }
}
