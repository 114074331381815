.block-setup {
  .block-voice-playback {
    background-color: $gray-200;
    height: 40px;
    padding: 18px 20px;

    .voice-track-well {
      border: 1px solid $gray-400;
      height: 4px;

      .voice-track {
        background-color: $blue;
        height: 3px;
        width: 0;
      }
    }
  }
}
