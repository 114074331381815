///Instruction Styles
.block-begin-test {
  .block-instructions-msg {
    text-align: center;

    h4 {
      font-size: 1.7rem;
      font-weight: bold;
      margin: 1.8rem auto 1.4rem;
    }

    p {
      margin: 0 auto 1.4rem;
      width: auto;
    }
  }

  .instructions-button-header {
    color: $orange;
    font-size: 2.5rem;
    font-weight: normal;
  }
}
