.block-steps {
  li {
    border-right: 1px solid $gray-500;
    color: $gray;
    display: inline-block;
    font-size: 1.6rem;
    padding: 0 6px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      border-right: 0;
    }

    h3 {
      display: inline;
      font-size: 1.8rem;
    }

    p {
      display: none;
    }

    &.selected {
      h3,
      p {
        color: $dark-orange;
        display: inline;
      }
    }

    &.checked {
      h3 {
        color: $green;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .block-steps {
    border: 1px solid $gray-400;

    ul {
      &.steps-5 {
        @include step-width-mixin(5);
      }

      &.steps-4 {
        @include step-width-mixin(4);
      }

      &.steps-3 {
        @include step-width-mixin(3);
      }

      &.steps-2 {
        @include step-width-mixin(2);
      }
    }

    li {
      border-right: 0;
      color: $gray-600;
      display: inline-block;
      margin-right: -4px;
      padding: 0;
      font-size: 1.8rem;

      .step-content {
        display: inline-block;
        padding-left: 6px;

        h3 {
          display: block;
          font-weight: bolder;
          margin: 6px auto 0;

          &::before {
            content: 'Step ';
          }
        }

        p {
          display: block;
          font-size: 1.1rem;
          margin: 0;
        }
      }

      &::after {
        background-image: url('#{$assetpath}/images/step-unchecked.png');
        content: '';
        display: inline-block;
        float: right;
        height: 51px;
        vertical-align: top;
        width: 42px;
      }

      &.selected {
        background-color: $orange;

        h3,
        p {
          color: $l-black;
        }
      }

      &.checked {
        h3 {
          color: $green;
        }

        &::after {
          background: url('#{$assetpath}/images/step-checked.png') no-repeat;
          background-position-x: right;
          background-size: 102%;
          background-position-y: center;
        }
      }

      &.before-selected {
        &::after {
          background-color: $orange;
        }
      }

      &:last-child {
        &::after {
          background: none;
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .block-steps {
    .selected {
      .step-content {
        h3 {
          display: block;
          font-weight: bolder;
          margin: 6px auto 0;

          &::before {
            content: 'Step ';
          }
        }
      }
    }
    .checked {
      .step-content {
        h3 {
          font-weight: bolder;
        }
      }
    }
    li {
      font-size: 1.8rem;
      .step-content {
        p {
          font-size: 1.2rem;
          display: none;
        }
        h3 {
          display: block;
          font-weight: 400;
          margin: 6px auto 0;
          font-size: 1.6rem;
        }
      }
    }
  }
}
