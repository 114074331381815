@mixin btn-mixin($height, $size) {
  height: $height;

  @if $size == big {
    padding: 0 2.8rem;
  } @else if $size == small {
    padding: 0 .7rem;
  }
}

@mixin icon-mixin($icon-position, $content, $icon-size, $icon-padding) {
  @if $icon-position == before {
    &::before {
      content: $content !important;
      font-family: FontAwesome;
      font-size: 1.2rem;
      font-weight: normal;
      padding-right: $icon-padding;
      @include media-breakpoint-up(sm) {
        font-size: $icon-size;
      }
    }
  } @else {
    &::after {
      content: $content !important;
      font-family: FontAwesome;
      font-size: $icon-size;
      font-weight: normal;
      padding-left: $icon-padding;
    }
  }
}

@mixin step-width-mixin($step-number) {
  @if $step-number == 5 {
    li {
      width: 20%;
    }
  } @else if $step-number == 4 {
    li {
      width: 25%;
    }
  } @else if $step-number == 3 {
    li {
      width: 33.3%;
    }
  } @else if $step-number == 2 {
    li {
      width: 50%;
    }
  }
}

@mixin form-field-mixin {
  border-color: $gray-600;
  border-radius: 0;
  height: 4.6rem !important;
}

@mixin title-mixin($color, $icon, $icon-url) {
  @include media-breakpoint-up(sm) {
    font-size: 3.6rem;
  }

  color: $color;
  font-size: 2.2rem;
  font-weight: bold;

  sup {
    @include media-breakpoint-up(sm) {
      top: -1.2rem;
    }
    top: -6rem;
  }

  @if($icon) {
    &::before {
      @include media-breakpoint-up(sm) {
        height: 3.5rem;
        margin-right: 1rem;
        vertical-align: -0.4rem;
        width: 3rem;
        display: inline-block;
      }

      background: url($icon-url) no-repeat;
      background-size: 100%;
      content: '';
      display: block;
      height: 3rem;
      margin-right: 0.6rem;
      margin: 0 auto;
      width: 2.5rem;
    }
  }
}

@mixin title-test-select-mixin($icon-url) {
  font-size: 2rem;
  margin-bottom: 0;

  &::before {
    @include media-breakpoint-up(sm) {
      margin-right: 4.4rem;
    }

    background: url($icon-url) no-repeat;
    background-size: 100%;
    content: '';
    display: inline-block;
    height: 3.2rem;
    margin-right: 1.4rem;
    vertical-align: -0.6rem;
    width: 3.2rem;
  }
}

@mixin system-check-status($icon-url) {
  background: $white url($icon-url) center no-repeat;
  border: 1px solid $gray;
}
