.modal-content {
  border: 0;
  border-radius: 0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .4);

  .modal-body {
    padding-top: 2rem;
  }

  .modal-footer {
    border-top: 0;
    padding: 2rem;
  }
}

.modal-dialog {
  max-width: 30rem;
}


.alert-block {
  margin-bottom: 2rem;

  h3 {
    font-size: 1.4rem;
    font-weight: bold;
  }

  ul {
    padding-left: 2rem;

    li {
      font-size: 1.4rem;
      list-style: disc;
    }
  }
}
