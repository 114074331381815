// $assetpath: 'https://opic.lti-inc.net:444'; //look into adding this to gulp prod task
$assetpath: 'https://opic.actfltesting.org'; //look into adding this to gulp prod task

$l-black: #212529;
$gray: #666D73;
$silver: #CCCCCC;
$platinum: #E8E8E8;
$dark-orange: #D54406;
$red-orange: #FF4A0F;
$orange: #FF6633;
$red: #EA131B;
$cherry-red: #7B0F13;
$purple: #8960B5;
$green: #15874E;
$blue: #3366FF;
$medium-blue: #0036D6;

$d-blue: #00f;
$l-gray: #eee;
$d-gray: #999;
$brand-opic-blue: #36f;
$brand-wpt-green: #42ad79;
$brand-lpt-purple: #8960b5;
$brand-rpt-orange: $orange;

$video-height: 30.4rem;
$video-width: 30.0rem;

$max-w: 96.2rem;
$min-w: 73.8rem;

// In a custom variables file...
$theme-colors: (
  primary: $orange,
  start-recording: $orange,
  stop-recording: $orange,
  play-recording: $orange
);

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer,
  2: ($spacer * 2),
  3: ($spacer * 3),
  4: ($spacer * 4),
  5: ($spacer * 5),
  6: ($spacer * 6),
  7: ($spacer * 7),
  8: ($spacer * 8),
  9: ($spacer * 9),
);
