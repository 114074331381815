.prompt-list {
  li {
    background-color: $gray;
    box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, .2);
    color: $white;
    display: inline-block;
    font-weight: bold;
    height: 3.6rem;
    line-height: 3.6rem;
    margin-bottom: .5rem;
    text-align: center;
    width: 3.6rem;

    &.visited {
      background-color: $green;
      color: $white;
    }
    &.active {
      background-color: $orange;
      color: $l-black;
    }
  }
}
