@import 'modules/variables';
@import 'modules/bootstrap';
@import 'modules/mixins';

html {
  font-size: 10px; //use this to scale
  height: 100%;
  width: 100%;
}

body {
  font: normal normal 1.4rem/2.1rem Arial;
}

a {
  &:active,
  &:hover {
    text-decoration: underline;
  }
  color: #D54406;
}

h1 {
  font-size: 2.5rem;
  @media (max-width: 768px) {
    font-size: 2rem;
  }
}

h2 {
  font-size: 2.4rem;
  font-weight: normal;

  &.opic-header-plain {
    @include title-mixin($brand-opic-blue, false, null);
  }

  &.wpt-header-plain {
    @include title-mixin($brand-wpt-green, false, null);
  }

  &.lpt-header-plain {
    @include title-mixin($brand-lpt-purple, false, null);
  }

  &.rpt-header-plain {
    @include title-mixin($brand-rpt-orange, false, null);
  }

  &.header-primary {
    border-bottom: 1px solid $gray-400;
    font-size: 1.29em;
    font-weight: bold;
    margin-bottom: 0;
    padding: 6px 0;
    @include media-breakpoint-down(sm) {
      font-size: 1.6rem;
    }
  }

  &.header-secondary {
    font-size: 1.29em;
    font-weight: bold;
    margin-bottom: 0;
    padding: 6px 0;
    @include media-breakpoint-down(sm) {
      font-size: 1.6rem;
      text-align: center;
      margin-right: 20px;
    }
  }
}

h3 {
  font-size: 1.2rem;
  font-weight: 700;
    @include media-breakpoint-up(sm) {
    font-size: 1.3rem;
    font-weight: 700;
  }
}

h4 {
  font-size: 1rem;
}

sup {
  font-size: 1rem;
  padding-left: 3px;
}

ul {
  list-style: none;
  margin: 0;
  padding-left: 0;
}

ul {
  &.list-plain {
    li {
      margin-bottom: 2rem;
    }
  }

  &.list-blue-disc {
    padding-left: 2rem;

    li {
      margin-bottom: 1rem;

      &::before {
        color: $blue;
        content: '\2022';
        display: inline-block;
        font-size: 3rem;
        margin-left: -2rem;
        vertical-align: middle;
        width: 2rem;
      }
    }
  }
}

ol {
  &.list-numbered {
    @include media-breakpoint-down(sm) {
      padding-left: 50px;
      font-size: 1.2rem;
    }
    counter-reset: li;
    list-style: none;
    padding-left: 50px;

    li {
      margin-bottom: 2rem;
      position: relative;
      &::before {
        font-size: 1.6rem;
        border-bottom: 2px solid $gray-400;
        border-right: 2px solid $gray-400;
        color: $blue;
        content: counter(li);
        counter-increment: li;
        font-weight: bold;
        left: -50px;
        line-height: 0;
        padding: 16px 14px;
        position: absolute;
        top: 0;
        @include media-breakpoint-up(sm) {
          font-size: 1.8rem;
        }
      }
    }
  }
}

p {
  @include media-breakpoint-down(sm) {
    font-size: 1.2rem;
    line-height: 22px;
  }
}

.hide {
  display: none;
}

// Opera Remove Detach Video
html > div { display: none; }

.actfl-test-container {
  box-sizing: border-box;
  max-width: $max-w;
  min-width: $min-w;
  // padding: 2rem 3rem;
  .add-con {
    // border: 1px solid #eee;
    margin: 23px auto;

    &.add-con-stretch {
      width: 698px;
    }
  }
  .block-actfl-lti-branding {
    img{
      width: 147px;
    } 
  }  
}

.modal {
  @media (max-width: 448px) {
    padding-right: 0px !important;
  }
}

.modal-dialog {
  max-width: 40rem;
  margin: 1.75rem auto;
  @media (max-width: 448px) {
    display: flex;
    align-items: center;
    min-height: 93vh;
    width: 80%;
  }
}

.actfl-test-header {
  border-bottom: 2px solid $gray;
  img{
    width: 322px;
  }
}

@include media-breakpoint-down(sm) {
  .actfl-test-header {
    border-bottom: 1px solid $gray;
    padding-left: 0;
    padding-right: 0;
    img{
      width: 158px;
    }
  }
  .actfl-test-container {
    max-width: none;
    min-width: 0;
    .add-con {  
      &.add-con-stretch {
        width: auto;
      }
    }
    .block-actfl-lti-branding{
      img{
        width: 120px;
      } 
    }
  }
}

//Shared Components
@import 'modules/buttons';
@import 'modules/branding';
@import 'modules/forms';
@import 'modules/learnosity';
@import 'modules/prompt-list';
@import 'modules/modal-messages';
@import 'modules/opic-components';
@import 'modules/steps';
@import 'modules/videojs-custom';

//Screen Specific
@import 'modules/system-check';
@import 'modules/mic-access';
@import 'modules/login';
@import 'modules/welcome';
@import 'modules/survey-questions';
@import 'modules/setup';
@import 'modules/begin-test';
@import 'modules/policy-agreement';
@import 'modules/test-complete';
